<template>
  <van-popup
    v-model="showPicker"
    class="fill-in-wrap-pop"
    position="right"
    style="width: 100%; height: 100%"
  >
    <div class="fill-in-wrap">
      <div class="head">进件材料</div>
      <div class="content fillin-upload-ctn">
        <div v-for="(field, index) in materialList" :key="index">
          <van-cell
            is-link
            clickable
            @click="clickPreviewFn(field)"
            :value="`已上传${field?.lists?.length ?? 0}张`"
            title-class="fillin-upload-ctn-title"
          >
            <template #title>
              <span v-if="field.isRequired == 'Y'" class="color-r m_r_8"
                >*</span
              >
              <span class="custom-title">{{ field.name }}</span>
            </template>
            <template #label>
              <div class="dis_flex ali_it_cen">
                <span
                  v-if="field.exampleUrl"
                  class="custom-title color-blue m_r_16"
                  @click="previewDemo(field.exampleUrl)"
                >
                  <van-icon name="eye-o" />
                  示例
                </span>
                <span class="custom-title"
                  >({{ acceptTipsFn(field.attachType) }})</span
                >
              </div>
            </template>
          </van-cell>
        </div>
      </div>
      <div class="btns">
        <van-button size="small" @click="handleClose">返回</van-button>
        <van-button type="info" size="small" @click="saveFn">保存</van-button>
      </div>
    </div>
    <FillInvUploadDetail
      v-model="showDetailPicker"
      :detail="detailInfo"
      @update="setMaterialList"
    ></FillInvUploadDetail>
  </van-popup>
</template>
<script>
import { ImagePreview } from "vant";
import {
  getProductMaterialConfigByBizNo,
  getImagesByBizNo,
  saveApplyImagesConfirm
} from "@/api";
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    // 需要填的表单内容
    detail: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    FillInvUploadDetail: () => import("./FillInvUploadDetail.vue")
  },
  watch: {
    showPicker(v) {
      if (v) {
        // console.log("传入的表单内容", this.fields);
        try {
          this.materialList = [];
          console.log("1212 :>> ", 1212);
          this.init();
        } catch (e) {
          console.log("查看报错", e);
          this.materialList = [];
        }
      } else {
        this.materialList = [];
      }
    }
  },
  computed: {
    showPicker: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      }
    }
  },
  data() {
    return {
      detailInfo: {},
      showDetailPicker: false,
      pageLoading: false,
      materialList: [], // 进件材料上传
      materialConfig: [] // 进件材料上传
    };
  },
  created() {},
  methods: {
    async init() {
      await this.getProductConfigs();
      this.setMaterialList();
    },
    // 产品配置
    async getProductConfigs() {
      try {
        this.pageLoading = true;
        const { bizNo } = this.detail;
        const resp2 = await getProductMaterialConfigByBizNo(bizNo);
        this.materialConfig = resp2?.data || [];
      } catch (error) {
        this.$errMsg(error);
      } finally {
        this.pageLoading = false;
      }
    },

    async setMaterialList() {
      const { bizNo } = this.detail;
      const res = await getImagesByBizNo(bizNo);
      const materialList = [...this.materialConfig].map((item) => ({
        ...item,
        bizNo: this.detail.bizNo,
        lists: (res.data[item.detailId] || []).map((item2) => ({
          ...item2,
          fileUrl: item2.imageUrl,
          fileName: item2.imageName
        }))
      }));
      this.materialList = [...materialList];
    },

    previewDemo(url) {
      ImagePreview({ images: [url], closeable: true });
    },
    async saveFn() {
      try {
        this.pageLoading = true;
        for (let item of this.materialList) {
          if (item.isRequired == "Y" && !item.lists.length) {
            this.$toast("请上传" + item.name);
            return;
          }
        }
        await saveApplyImagesConfirm(this.detail.bizNo);
        this.$emit("update");
        this.showPicker = false;
      } catch (error) {
        this.$errMsg(error);
      } finally {
        this.pageLoading = false;
      }
    },
    handleClose() {
      // 用户点击关闭图标时触发
      this.showPicker = false;
    },
    acceptTipsFn(fileType) {
      if (fileType == "PIC") {
        return "请上传.jpg, .jpeg, .png格式文件";
      } else if (fileType == "VIDEO") {
        return "请上传视频格式文件";
      } else if (fileType == "DOC") {
        return "请上传pdf格式文件";
      } else {
        return "image/*,video/*";
      }
    },
    clickPreviewFn(item = {}) {
      this.detailInfo = { ...item };
      console.log("this.detailInfo :>> ", this.detailInfo);
      this.showDetailPicker = true;
    }
  }
};
</script>
<style lang="scss" scoped>
.fill-in-wrap-pop {
  :deep(.fill-in-wrap) {
    height: 100%;
    display: flex;
    flex-direction: column;
    .head {
      height: 96px;
      text-align: center;
      line-height: 96px;
      font-size: 32px;
      font-weight: 600;
      color: #333;
      border-bottom: 1px solid #efefef;
    }
    .content {
      flex: 1;
      overflow-y: auto;

      .color-r {
        color: red;
      }
      .color-blue {
        color: rgb(24, 144, 255);
      }
    }
    .fillin-upload-ctn-title {
      min-width: 65vw;
    }
    .btns {
      border-top: 1px solid #efefef;
      height: 112px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      box-shadow: 0 -5px 10px #efefef;
      .van-button {
        min-width: 160px;
      }
    }
  }
}
</style>
